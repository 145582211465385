import { gql } from '@apollo/client';

import { unitSupportingMaterialFragment } from './sections/supporting-materials/supporting-materials.graphql';
import { unitCategoryDetailsFragment } from './sections/unit-details/unit-categories/unit-categories.graphql';
import {
  unitLandscapeImageFragment,
  unitSquareImageFragment,
} from './sections/images/hero-image.graphql';
import { OutcomesFragment } from 'approot/shared/graphql/outcomes/outcomes.graphql';
import { SubjectFragment } from 'approot/shared/graphql/startup-data/startup-data.graphql';
import {
  AssessmentFragment,
  LessonFragment,
  materialsFragment,
} from 'approot/shared/graphql/lesson/lesson.graphql';
import {
  unitStrandsFragment,
  unitSubStrandsFragment,
} from 'approot/unit/sections/strands/strands.graphql';
import { LessonMaterialFragment } from 'approot/shared/lesson-materials/lesson-material-fragment';

export const unitDetailsFragment = gql`
  fragment UnitDetailsFragment on Unit {
    id
    name
    slug
    subjects {
      ...SubjectFragment
    }
    years {
      id
      name
      label
    }
    ...UnitLandscapeImageFragment
    releaseMessage
    lastReleasedAt
  }
  ${unitLandscapeImageFragment}
  ${SubjectFragment}
`;

export const unitPlannerFragment = gql`
  fragment UnitPlannerFragment on Unit {
    id
    name
    unitStatus
    unitPlannersInfo {
      plannerId
      plannerItemGroupId
      term
      plannerItems {
        id
        itemId
        itemType
      }
    }
  }
`;

export const unitConceptsFragment = gql`
  fragment UnitConceptsFragment on Unit {
    id
    textualConcepts {
      id
      description
    }
    bigIdeas {
      id
      description
    }
  }
`;

// Note: if you change the query name: GetUnitDetails
// also update in apollo.tsx RETRY_OPERATIONS
export const GET_UNIT_DETAILS = gql`
  query GetUnitDetails($input: GetUnitByIdInput!) {
    getUnitById(input: $input) {
      id
      name
      authorId
      unitStatus
      isIntegrated
      isAnnotated
      comingSoonMessage
      term
      outcomesByCurriculums {
        curriculum
        outcomes {
          ...OutcomesFragment
          description
          metaInfo
        }
      }
      ...UnitDetailsFragment
      data {
        vocabularies
        generalCapabilities
        crossCurriculumPriorities
      }
      ...UnitSupportingMaterialsFragment
      overviewJson
      keyInquiryQuestion
      curriculumYears {
        curriculum
        years
      }
      lessons {
        ...LessonFragment
        isAnnotated
        outcomes {
          ...OutcomesFragment
          description
          metaInfo
        }
        performanceExpectations {
          ...OutcomesFragment
          description
          metaInfo
        }
        materials {
          ...MaterialsFragment
        }
        activitiesMaterials {
          id
          lessonMaterials {
            ...LessonMaterialFragment
          }
        }
        bookmarked
        duration
        subjects {
          ...SubjectFragment
        }
        years {
          id
          name
          label
        }
        lessonActivities {
          id
          status
        }
      }
      assessments {
        ...AssessmentFragment
        outcomes {
          ...OutcomesFragment
          description
          metaInfo
        }
        performanceExpectations {
          ...OutcomesFragment
          description
          metaInfo
        }
        materials {
          ...MaterialsFragment
        }
        activitiesMaterials {
          id
          lessonMaterials {
            ...LessonMaterialFragment
          }
        }
        bookmarked
        duration
        subjects {
          ...SubjectFragment
        }
        years {
          id
          name
          label
        }
        assessmentActivities {
          id
          status
        }
      }
      ...UnitLandscapeImageFragment
      ...UnitSquareImageFragment
      ...UnitCategoryDetailsFragment
      ...UnitPlannerFragment
      ...UnitConceptsFragment
      ...UnitStrandsFragment
      ...UnitSubStrandsFragment
      canManageCurrentContent
    }
  }
  ${unitLandscapeImageFragment}
  ${unitSquareImageFragment}
  ${unitSupportingMaterialFragment}
  ${unitCategoryDetailsFragment}
  ${unitDetailsFragment}
  ${unitPlannerFragment}
  ${unitConceptsFragment}
  ${unitStrandsFragment}
  ${unitSubStrandsFragment}
  ${OutcomesFragment}
  ${LessonFragment}
  ${AssessmentFragment}
  ${materialsFragment}
  ${LessonMaterialFragment}
`;

export const GET_UNIT_VOCABULARY_SUGGESTIONS = gql`
  query GetUnitVocabularySuggestions($input: GetVocabularySuggestionsInput!) {
    suggestions(input: $input)
  }
`;

export const SAVE_UNIT_OVERVIEW = gql`
  mutation SaveUnitOverview($input: UpdateUnitOverviewInput!) {
    updateUnitOverview(input: $input) {
      id
      overviewJson
    }
  }
`;

export const SAVE_UNIT_KEY_QUESTION = gql`
  mutation SaveUnitKeyQuestion($input: UpdateUnitKeyInquiryQuestionInput!) {
    updateUnitKeyInquiryQuestion(input: $input) {
      id
      keyInquiryQuestion
    }
  }
`;

export const SAVE_UNIT_CROSS_CURRICULUM_PRIORITIES = gql`
  mutation SaveUnitCrossCurriculumPriorities(
    $input: UpdateUnitCrossCurriculumPrioritiesInput!
  ) {
    updateUnitCrossCurriculumPriorities(input: $input) {
      id
      data {
        crossCurriculumPriorities
      }
    }
  }
`;
export const SAVE_UNIT_GENERAL_CAPABILITIES = gql`
  mutation SaveUnitGeneralCapabilities(
    $input: UpdateUnitGeneralCapabilitiesInput!
  ) {
    updateUnitGeneralCapabilities(input: $input) {
      id
      data {
        generalCapabilities
      }
    }
  }
`;

export const SAVE_UNIT_STATUS = gql`
  mutation SaveUnitStatus($input: UpdateUnitStatusInput!) {
    updateUnitStatus(input: $input) {
      id
      unitStatus
    }
  }
`;

export const SAVE_COMING_SOON_MESSAGE = gql`
  mutation SaveComingSoonMessage($input: UpdateUnitComingSoonMessageInput!) {
    updateUnitComingSoonMessage(input: $input) {
      id
      comingSoonMessage
    }
  }
`;

export const SAVE_UNIT_NAME = gql`
  mutation SaveUnitName($input: UpdateUnitNameInput!) {
    updateUnitName(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_UNIT_VOCABULARIES = gql`
  mutation UpdateUnitVocabularies($input: UpdateUnitVocabulariesInput!) {
    updateUnitVocabularies(input: $input) {
      id
      data {
        vocabularies
      }
    }
  }
`;

export const UPDATE_CURRICULUM_YEARS = gql`
  mutation UpdateCurriculumYears($input: UpdateCurriculumYearsInput!) {
    updateCurriculumYears(input: $input) {
      id
      curriculumYears {
        curriculum
        years
      }
    }
  }
`;
